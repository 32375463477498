@tailwind base;
@tailwind components;
@tailwind utilities;


/* width */
::-webkit-scrollbar {
  width: 10px;
  z-index: -1;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  opacity: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
  opacity: 10px;
  border-radius: 10px;
}

body {
  height: 100vh;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-400px * 7 - 0.5rem * 14));
  }
}

.animate-scroll {
  animation: scroll 20s linear infinite;
}

.paused {
  animation-play-state: paused;
}

.image {
  object-fit: cover;
}

.navbar {
  z-index: 40;
  /* Un valor alto para asegurar que esté por encima de otros componentes */
}

.card-carousel {
  z-index: 1;
  /* Un valor más bajo para asegurar que se desplace detrás del Navbar */
}
.backgroundred{
  background-color: #f80a0a;
}
 